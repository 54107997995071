.CeilingBanner {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url('D:/ND Services/nd-services/public/c0.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    border-radius: 0 0 10px 10px;
    background-size: 120%;
    background-position: center;
    background-repeat: no-repeat;
    animation: zoomOut 5s ease-out forwards;
}

@keyframes zoomOut {
    0% {
        background-size: 120%;
    }

    100% {
        background-size: 100%;
    }
}
@media screen and (max-width: 768px) {
    .CeilingBanner {
        height: 300px;
    }
}
