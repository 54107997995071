.Prefooter {
    height: auto;
    display: grid;
    padding: 100px;
    padding-bottom: 200px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    place-items: center;
}
.read-more{
    width: 120px;
}

.prefooter-icons {
    display: flex;
    gap: 10px;
    margin-top: -50px;
    cursor: pointer;
}

.prefooter-next {
    margin-top: 80px;
}

.icon-1 {
    height: 400px;
    width: 400px;
    object-fit: contain;
    margin-bottom: 0px;
}

.prefooter-head {
    font-family: 'Playfair Display', serif;
    color: #082944;
}

.prefooter-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
}

.prefooter-btn-row {
    display: flex;
    gap: 10px;
}

.head-text {
    font-size: 18px;
    font-weight: bold;
    color: rgb(56, 56, 56);
}

.sub-text {
    font-size: 16px;
    margin-top: -13px;
}

.prefooter-btn, .prefooter-btn-1 {
    background-color: #072a35;
    color: aliceblue;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
}

@media (max-width: 480px) {
    .Prefooter {
        margin-top: 50px;
        grid-template-columns: 1fr;
        padding: 20px;
        margin-bottom: 120px;
    }

    .icon-1 {
        height: 300px;
        width: 300px;
        object-fit: contain;
        margin-bottom: 0px;
    }

    .prefooter-left {
        align-items: center;
        place-content: center;
        margin-bottom: 20px;
    }
    .prefooter-icons {
        display: flex;
        gap: 10px;
        margin-top: -40px;
        cursor: pointer;
    }
    .head-text {
        margin-top: -10px;
        font-size: 18px;
        font-weight: bold;
        color: rgb(56, 56, 56);
    }
    
    .sub-text {
        font-size: 16px;
        margin-top: -10px;
    }
    
    .prefooter-next {
        margin-top: 30px;
        text-align: center;
    }

    .prefooter-head {
        font-size: 28px;
    }

    .head-text, .sub-text {
        text-align: center;
    }

    .prefooter-btn-row {
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
}
