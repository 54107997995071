.land-about {
    margin: 30px 40px;
    height: auto;
    margin-top: 150px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.land-about-left {
    display: flex;
    flex-direction: column;
}

.head-bottom {
    margin-top: -10px;
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    color: #093141;
    text-align: left;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: 0.5s ease;
}

.head-bottom span {
    display: inline-block;
    position: relative;
    z-index: 1;
    background-color: transparent;
    transition: background-size 1s ease-in-out;
    background-image: linear-gradient(to right, #e6a122 100%, transparent 0%);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}

.head-bottom .slide-background {
    background-size: 100% 100%;
}

.para-dark {
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    opacity: 0;
    transition: 1s ease;
}

.read-more-btn {
    margin-top: -25px;
    border: 1px solid rgb(41, 41, 41);
    padding: 10px;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-weight: 600;
    text-align: center;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: black;
    gap: 5px;
    cursor: pointer;
    transition: 0.5s ease;
}

.read-more-btn:hover {
    background-color: #0f5b2b;
    border: none;
    color: white;
}

.arrow-icon {
    transition: 0.2s ease;
    color: rgb(255, 255, 255);
}

.read-more-btn:hover .arrow-icon {
    transform: translateX(10px);
    color: white;
}

@media (max-width: 768px) {
    .land-about {
        grid-template-columns: 1fr;
        margin: 20px;
        gap: 30px;
        text-align: center;
    }

    .head-bottom {
        font-size: 32px;
        text-align: center;
    }

    .para-dark {
        font-size: 14px;
        text-align: left;
    }

    .read-more-btn {
        margin-top: -10px;
    }
}

/* For tablets */
@media (max-width: 1024px) {
    .land-about {
        grid-template-columns: 1fr;
        margin: 20px;
        gap: 40px;
        margin-top: 100px;
    }

    .head-bottom {
        font-size: 40px;
        text-align: left;
    }

    .para-dark {
        font-size: 14px;
        text-align: justify;
    }
}
