.Navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
}

.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    background-color: rgb(12, 32, 61);
    padding: 0 20px;
    overflow: hidden;
}

.top-bar-text {
    color: white;
    font-size: 14px;
}

.top-bar-next {
    display: flex;
    gap: 8px;
}

.bottom-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    height: 55px;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(59, 59, 59, 0.3);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.icon-searchbar {
    display: flex;
    align-items: center;
    gap: 15px;
    color: black;
}

.icon {
    height: 80px;
    width: 80px;
    object-fit: contain;
}

.searchbar {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 180px;
    color: rgb(34, 34, 34);
}

.search-btn {
    background-color: rgb(20, 73, 134);
    border: none;
    margin-left: -12px;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.search-icon {
    color: white;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 20px;
}

.nav-links a {
    text-decoration: none;
    color: rgb(20, 73, 134);
    font-size: 14px;
    font-weight: 600;
}

.nav-links a:hover {
    color: rgb(10, 50, 90);
}

.services-dropdown {
    position: relative;
}

.services-dropdown a {
    text-decoration: none;
    color: rgb(20, 73, 134);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.dropdown-icon-1 {
    margin-left: 5px;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 100%;
    width: 180px;
    left: 0;
    right: 0;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    padding: 10px;
}

.dropdown-row {
    display: flex;
    gap: 10px;
}

.dropdown-icon {
    margin-right: 10px;
}

.dropdown-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dropdown-column a {
    text-decoration: none;
    color: rgb(20, 73, 134);
    font-size: 14px;
    font-weight: 600;
}

.dropdown-column a:hover {
    color: rgb(10, 50, 90);
}

.contact-button {
    margin-left: 20px;
}

.contact-btn {
    display: flex;
    align-items: center;
    padding: 8px 18px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid rgb(85, 120, 160);
    color: rgb(20, 73, 134);
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: 0.5s ease;
}

.contact-btn:hover {
    background-color: rgb(20, 73, 134);
    color: white;
}

.phone-icon {
    margin-left: 8px;
}

@media screen and (max-width: 480px) {
    .bottom-bar {
        height: 55px;
        gap: 10px;
        padding-left: 5px;
        padding-right: 0px;
    }
    .searchbar{
        width: 150px;
    }

    .icon-searchbar {
        width: 100px;
        justify-content: space-between;
    }

    .top-bar {
        height: 40px;
    }

    .nav-links {
        display: none;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
        padding: 10px;
    }

    .contact-button {
        display: none;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 90px;
        right: -5px;
        border-radius: 8px;
        gap: 15px;
        z-index: 999;
        color: rgb(243, 243, 243);
        background-color: #102f57;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        transition: all 0.3s ease;
        padding: 15px;
        margin: 10px;
    }

    .mobile-menu a {
        color: #ffffff;
        text-decoration: none;
        font-weight: 500;
        padding: 10px 15px;
        border-radius: 5px;
        transition: background-color 0.3s ease, color 0.3s ease;
    }
}
.nav-links a {
    position: relative;
    padding-bottom: 10px; /* Space for the horizontal line */
}

.nav-links .active {
    font-weight: bold; /* Style for the active link */
}

.nav-links .active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0; /* Start width of the line (animated) */
    height: 3px; /* Height of the line */
    background-color: #255088; /* Color of the line */
    animation: growLine 0.5s ease-out forwards; /* Animation */
}

/* Animation to grow the line from 0 to full width */
@keyframes growLine {
    from {
        width: 0;
    }
    to {
        width: 100%; /* Full width of the link */
    }
}


@media screen and (min-width: 769px) {
    .menu-icon {
        display: none;
    }

    .nav-links {
        display: flex;
    }

    .mobile-menu {
        display: none;
    }
}