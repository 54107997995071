.AboutContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    margin: 80px;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for better readability */
    border-radius: 8px; /* Smooth edges for a modern look */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.about-head-1 {
    text-align: center;
    margin-top: 0;
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    color: rgb(13, 46, 65);
    line-height: 1.5; /* Proper line spacing for header */
    margin-bottom: 20px; /* Space between the heading and paragraph */
}

.about-para-1 {
    text-align: justify;
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    color: #333; /* Darker color for better contrast */
    line-height: 1.8; /* Enhanced line spacing for better readability */
    margin: 0 10px; /* Slight horizontal margin for better text alignment */
    word-spacing: 1.5px; /* Adds space between words for a cleaner look */
}

.about-para-1 ul {
    margin-top: 15px; /* Spacing before the list */
    padding-left: 20px; /* Indentation for bullet points */
}

.about-para-1 li {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 10px; /* Space between list items */
    color: #555; /* Slightly lighter text for list items */
}

@media (max-width: 780px) {
    .AboutContent {
        margin: 10px;
        padding: 15px;
    }

    .about-head-1 {
        font-size: 28px;
        text-align: center;
        margin-bottom: 15px;
    }

    .about-para-1 {
        font-size: 16px;
        line-height: 1.6;
        padding: 10px;
        text-align: justify;
    }

    .about-para-1 ul {
        padding-left: 15px;
    }
}
