.analytics {  
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 100px;
  background-color: white;
}

.analytics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
}

.analytics-item {
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ddd;
}

.analytics-title {
  text-align: left;
  margin-top: -10px;
  color: rgb(12, 32, 61);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.analytics-number {
  text-align: left;
  font-size: 32px;
  color: rgb(12, 32, 61);
  font-weight: bold;
}

.analytics-bar {
  width: 1%;
  height: 5px;
  background-color: rgb(12, 32, 61);
  transition: width 1.5s ease-in-out;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .analytics {
    padding: 10px;
  }
  
  .analytics-title {
    font-size: 15px;
  }

  .analytics-number {
    font-size: 20px;
  }

  .analytics-grid {
    grid-template-columns: 1fr;
  }
}
