.Services {
    background-color: rgb(19, 66, 94);
    padding-top: 50px;
    padding-bottom: 50px;
}

.services-head {
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 45px;
    color: rgb(249, 253, 255);
    margin-bottom: 60px;
}

.all-services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;  /* Default to 4 columns on desktop */
    gap: 20px;  /* Adds space between the items */
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 50px;
}

.service-1-head {
    font-family: 'Playfair Display', serif;
    color: #082944;
}

.current-service {
    opacity: 0;
    margin-top: 20px;
    height: auto;
    width: 280px;
    background-color: #f5fcfd;
    border-radius: 7px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    padding-bottom: 20px;
    transition: 0.5s ease;
    margin-top: 200px;
}

.im-service-1 {
    height: 250px;
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
}

.service-text {
    margin-top: -10px;
    text-align: center;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .all-services {
        grid-template-columns: 1fr; 
        justify-items: center;  
        padding-left: 0;
        padding-right: 0;
    }
    .Services {
        background-color: rgb(19, 66, 94);
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .current-service {
        width: 300px;
    }
    

    .current-service {
        margin-bottom: 20px; 
        opacity: 1;
        margin-top: 10px;  
    }
    
    .services-head {
        font-size: 32px; 
    }
}
