.Gypsum-services {
    padding-top: 70px;
    background-color: rgb(9, 41, 59);
    padding: 80px;
    align-items: center;
    place-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    animation: fade-in 1.5s ease-in-out;
}

.Gypsum-services-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 35px;
    padding-top: 30px;
}

.column-current {
    background-color: rgb(245, 252, 253);
    height: auto;
    width: 320px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(20px);
    opacity: 0;
    animation: slide-up 0.8s ease-in-out forwards;
}

.column-current:nth-child(2) {
    animation-delay: 0.2s;
}

.column-current:nth-child(3) {
    animation-delay: 0.4s;
}

.Gypsum-service-head {
    font-size: 22px;
    margin: 10px 0;
    color: #13425E;
    font-weight: bold;
}

.Gypsum-service-text {
    font-size: 16px;
    color: #666666;
    margin-bottom: 10px;
    line-height: 1.6;
    text-align: justify;
}

.column-circle {
    width: auto;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin-bottom: 15px;
}

.column-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.read-more {
    background-color: #13425E;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    width: 150px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.read-more:hover {
    background-color: #0A2638;
    transform: translateY(-3px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
    .Gypsum-services-layout {
        grid-template-columns: 1fr;
    }
    .column-current {
        margin-top: 30px;
        height: auto;
        width: 280px;
        border-radius: 10px;
    }
    .Gypsum-services {
        padding: 50px;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
