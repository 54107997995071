.footer {
    background-color: rgb(7, 34, 56);
    color: rgb(202, 200, 200);
    padding: 25px 15px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.footer-column {
    flex: 1;
    min-width: 220px;
    margin: 0 15px;
    padding: 0 15px;
}

.footer-column h3 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #ffdd43;
    text-transform: uppercase;
    border-bottom: 2px solid rgb(189, 189, 189);
    padding-bottom: 10px;
}

.footer-column a {
    display: block;
    color: rgb(214, 214, 214);
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 10px;
    transition: color 0.3s ease;
}

.footer-column a:hover {
    color: #bee5eb;
}

.footer-social {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.footer-social a {
    color: white;
    font-size: 20px;
    transition: color 0.3s ease;
}

.footer-social a:hover {
    color: #ffdd43;
}

.footer-column p {
    font-size: 14px;
    margin-top: 10px;
}

.footer-bottom {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #d1d1d1;
}
@media (max-width: 480px) {
    .footer-column {
        flex: 1;
        min-width: 220px;
        margin: 0px;
        padding: 0px;
    }
}
