.landing-poster{
    padding: 50px;
    display: none;
    background-color: white;
}

@media (max-width: 480px) {
    .landing-poster {
        position: relative; /* Creates a positioning context for the text */
        display: flex;
        justify-content: center;
        align-items: center;
 
    }
    
    .poster-01 {
        opacity: 0;
        transition: 1s ease;
        height: 350px;
        width: 350px;
        border-radius: 20px;
        object-fit: contain;
    }
    .poster-text, .with-text, .work-text {
        position: absolute; 
        color: rgb(58, 58, 58); 
        font-size: 15px;
        text-align: center;
        top: 62%; 
        left: -100%; /*25*/ 
        opacity: 0;
        z-index: 10; 
        transition: 2s ease;
    }
    .text-rect{
        display: flex;
        align-items: center;
        place-content: center;
        top: 71%;
        left: 8%;
        position: absolute;
        background-color: rgb(19, 50, 80);
        padding: 2px;
        z-index: 10;
        width: 120px;
        height: 30px;
    }
    .work-text{
        top: 12%;
        font-size: 10px;
        font-family: 'Courier New', Courier, monospace;
        left: 12%;
        opacity: 1;
    }
    .dream-text{
        color: white;
        opacity: 0;
        transition: 0.5s ease;
    }
    .with-text{
        top: 78%;
        opacity: 0;
    }
}