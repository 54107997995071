.PartitionContent {
    background-color: rgb(249, 252, 255);
    height: auto;
}

.PartitionBanner {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), 
                url('D:/ND Services/nd-services/public/assets2/partition3.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    background-size: cover;
    border-radius: 0 0 10px 10px;
    background-repeat: no-repeat;
}

.partition-head {
    font-family: 'Playfair Display', serif;
    font-size: 50px;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    margin: 0;
}

.banner-text {
    color: white;
    padding: 10px;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.slider {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    place-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.slider-images {
    display: flex;
    align-items: center;
    place-content: center;
    transition: transform 0.5s ease;
}

.slider-image {
    flex: 0 0 auto;
    width: 35%;
    height: 25%;
    padding: 0 10px;
    object-fit: contain;
    box-sizing: border-box;
}

.slider-image img {
    width: 100%;
    height: 300px;  
    border-radius: 5px;
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10;
    border-radius: 10%;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.slider-button:hover {
    background-color: rgba(235, 233, 233, 0.8);
    color: black;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.slider-indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.slide-counter {
    height: 8px;
    width: 8px;
    background-color: white;
    border: 1px solid rgb(122, 122, 122);
    border-radius: 50%;
    margin: 0 5px;
    transition: all 0.3s ease;
}

.partition-container {
    height: auto;
    margin-bottom: 100px;
}

.partition-head-1 {
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    color: rgb(13, 46, 65);
}

.partition-top {
    margin-top: 20px;
    margin-bottom: -50px;
    margin-left: 100px;
    margin-right: 100px;
}

.partition-para {
    text-align: justify;
    opacity: 0.9;
    margin-left: 100px;
    margin-right: 100px;
}

.PartitionContent {
    height: auto;
    place-content: center;
    font-size: 18px;
    margin-bottom: 150px;
}

.partition-content-top {
    margin: 100px;
}

.partition-head-2 {
    line-height: 45px;
    font-family: 'Playfair Display', serif;
    font-size: 35px;
    font-weight: 600;
    color: rgb(13, 46, 65);
    text-align: left;
}

.content-container {
    margin-top: 50px;
    height: 450px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: rgb(231, 243, 243);
    padding-left: 100px;
    padding-right: 100px;
}

.current-section {
    padding: 20px;
    align-items: center;
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    color: rgb(13, 51, 66);
    border-radius: 10px;
}

.partition-para-bottom {
    margin-top: -10px;
    text-align: justify;
    font-size: 16px;
    opacity: 0.9;
}

.section-para {
    margin-top: -10px;
    font-size: 15px;
    text-align: center;
}

.partition-content-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: auto;
    margin-bottom: -100px;
    background-color: rgb(255, 255, 255);
}

.content-bottom-left {
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    margin-top: 100px;
}

.content-partiton-im {
    height: 400px;
    margin-top: 120px;
    margin-left: 120px;
    border-radius: 2px;
}

.progressbar-container {
    margin-top: 20px;
}

.progress-bars {
    margin-top: 10px;
}

.bar-head {
    color: rgb(18, 57, 75);
    font-size: 20px;
}

.bar-para {
    font-size: 14px;
    margin-top: -20px;
    opacity: 0.8;
}

.bar {
    margin-top: -10px;
    width: 500px;
    height: 10px;
    background-color: rgb(162, 175, 196);
    border-radius: 5px;
}

.bar-inner-1,
.bar-inner-2,
.bar-inner-3,
.bar-inner-4 {
    height: 10px;
    width: 0%;
    background-color: rgb(51, 89, 119);
    border-radius: 5px;
    transition: 1s ease;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
    .PartitionBanner {
        height: 300px;
    }

    .partition-head {
        font-size: 30px;
    }
    .partition-top{
        margin: 0;
        padding: 0;
        margin-top: 50px;
    }
    .partition-para{
        font-size: 15px;
    }

    .partition-head-1 {
        font-size: 28px;
    }

    .partition-container {
        margin: 0 20px;
    }
    .partition-content-bottom{
        grid-template-columns: 1fr;
        height: auto;
    }

    .slider {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 50px;
    }
    .content-bottom-left{
        margin: 0;
        margin-right: 10%;
    }

    .slider-button {
        font-size: 1.5rem;
    }

    .slider-image {
        width: 250px;
        height: 250px;
        object-fit: cover;
    }

    .partition-content-top {
        margin: 50px 20px;
    }

    .partition-head-2 {
        font-size: 28px;
    }

    .content-container {
        grid-template-columns: 1fr;
        padding-left: 20px;
        padding-right: 20px;
        height: auto;
    }

    .content-bottom-left {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 50px;
    }

    .content-partiton-im {
        height: 100%;
        width: 100%;
        margin-left: 0px;
    }

    .progressbar-container {
        margin-top: 20px;
    }

    .bar {
        width: 80%;
    }
}
