body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    color: #343a40;
}
.partition-top{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 100px;
}

.boards-outer {
    padding: 0px;
    padding-bottom: 100px;
    padding-left: 50px;
    padding-right: 50px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.land-content {
    text-align: center;
    margin-bottom: 30px;
}

.partition-para {
    font-size: 16px;
    line-height: 1.6;
    color: #495057;
}

.boards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 25px;
    margin-left: 1%;
}

.current-board {
    height: 250px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.current-board:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.im-board {
    height: 220px;
    object-fit: cover;
    transition: opacity 0.3s;
}

.im-board:hover {
    opacity: 0.9;
}

/* Mobile View */
@media (max-width: 500px) {
    .swiper-wrapper {
        display: flex;
    }
    .boards-outer{
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .current-board{
        margin-top: 0;
        margin-left: 16px;
        margin-right: 16px;
    }
    .partition-para{
        font-size: 15px;
        margin: 0px;
    }

    .boards {
        display: block;
        margin: 0 auto;
    }

    .land-content {
        margin: 20px;
    }

    .im-board {
        height: 200px;
        object-fit: contain;
    }
}

/* Navigation Buttons */
.custom-prev,
.custom-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: #ffffff;
    color: #646464;
    border: 1px solid #e2e3e4;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.custom-prev:hover,
.custom-next:hover {
    background-color: #343a40;
    color: #ffffff;
    transform: scale(1.1);
}

.custom-prev {
    left: 10px;
}

.custom-next {
    right: 10px;
}

/* Pagination Styling */
.swiper-pagination {
    position: relative;
    bottom: 10px;
}

.swiper-pagination-bullet {
    background: #adb5bd;
    opacity: 1;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    transition: background-color 0.3s, transform 0.3s;
}

.swiper-pagination-bullet-active {
    background-color: #343a40;
    transform: scale(1.2);
}
