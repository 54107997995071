.qa-container {
    margin: 100px;
    margin-left: 350px;
    margin-right: 350px;
}

.qa-title {
    font-family: 'Playfair Display', serif;
    font-size: 32px;
    text-align: center;
    margin-bottom: 25px;
    color: #0d2e41;
}

.qa-list {
    margin-top: 10px;
}

.qa-item {
    margin-bottom: 15px;
    padding: 10px 10px;
    border-radius: 8px;
    transition: transform 0.2s, box-shadow 0.2s;
}

.qa-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(49, 49, 49, 0.1);
}

.qa-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #4d4d4d;
    cursor: pointer;
}

.qa-icon {
    font-size: 18px;
    color: #666;
    transition: transform 0.2s;
}

.qa-answer {
    margin-top: 10px;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #555;
    line-height: 1.8;
    text-align: justify;
}

.qa-item:last-child {
    margin-bottom: 0;
}

/* Media Queries */
@media (max-width: 1200px) {
    .qa-container {
        margin-left: 150px;
        margin-right: 150px;
    }
}

@media (max-width: 992px) {
    .qa-container {
        margin-left: 100px;
        margin-right: 100px;
    }
}

@media (max-width: 768px) {
    .qa-container {
        margin: 20px;
        margin-top: 100px;
        width: auto;
    }

    .qa-title {
        font-size: 28px;
    }

    .qa-question {
        font-size: 15px;
    }

    .qa-answer {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .qa-container {
        margin: 10px;
        padding-top: 100px;
    }

    .qa-title {
        font-size: 24px;
    }

    .qa-question {
        font-size: 14px;
    }

    .qa-answer {
        font-size: 13px;
        line-height: 1.6;
    }
}
