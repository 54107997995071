.contact-display{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(251, 254, 255);
    padding-top: 100px;
}
.contact-head{
    font-size: 40px;
    color: rgb(19, 66, 94);;
    font-family: 'Playfair Display', serif;
}
.icon-social{
    height: 25px;
    width: 25px;
    object-fit: contain;
    color: #0a2538;
}
.contact-upper{
    margin-bottom: 20px;
}
.contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 100px;
    color: white;
}

@media (max-width: 768px) {
    .contact-grid {
        grid-template-columns: 1fr;
    }
}

.contact-info-form {
    color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    margin-top: -30px;
    color: black;
}

.institute-name {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #0a2538;
}

.description {
    margin-bottom: 20px;
    line-height: 1.6;
}

.info-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 1rem;
}

.info-item svg {
    margin-right: 10px;
    color: #0a2538;
}

.social-icons {
    margin-top: 20px;
    display: flex;
    gap: 6px;
}

.icon {
    font-size: 1.5rem;
    color: #0a2538;
    cursor: pointer;
}

.enroll-btn {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: rgb(19, 66, 94);;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
}

.enroll-btn:hover {
    background-color: #0a2538;
    color: white;
}

.contact-form  {
    padding: 30px;
    border-radius: 10px;
    color: black;
    border: 1px solid #0a2538; 
}

.form-field {
    position: relative;
    margin-bottom: 20px;
    margin-right: 25px;
}

label {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 1rem;
    color: #061622;
    pointer-events: none;
    transition: all 0.3s ease;
}

input, textarea {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    transition: all 0.3s ease;
}

textarea {
    resize: none;
}

input:focus, textarea:focus {
    outline: none;
    border-color: #0a2538; 
}

.focused label {
    top: -10px;
    left: 10px;
    font-size: 0.8rem;
    color: #0a2538;
    z-index: 1000;
}

.submit-btn {
    width: 100%;
    padding: 15px;
    background-color: rgb(19, 66, 94);
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0a2538;
}

@media (max-width: 480px) {
    .contact-grid {
        padding: 20px;
    }

    .contact-info {
        margin-bottom: 30px;
    }

    .enroll-btn {
        font-size: 1rem;
    }

    .submit-btn {
        font-size: 1rem;
    }
}
