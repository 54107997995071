.ContactBoxes{
    height: auto;
    padding-bottom: -60px;
}
.contact-inner{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    place-items: center;
    padding-top: 100px;
    margin-left: 330px;
    margin-right: 330px;
}
@media (max-width: 480px) {
    .contact-inner{
        grid-template-columns: 1fr;
        margin-left: 0;
        margin-right: 0;
    }
}
.contact-box-1{
    background-color: rgb(19, 66, 94);;
    height: 220px;
    width: 170px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin-top: 50px;
    transition: 0.5s ease;
}
.copy-text{
    color: white;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}
.contact-box-icons{
    margin-top: 40px;
    opacity: 1;
    transition: 0.4s ease;
   
}
.contact-box-1:hover .contact-box-icons{
    transform:rotate(20deg) translateY(-20px);
}