
.about-head-01{
    color: white;
}
.maps-left{
    display: flex;
    flex-direction: column;
}

.maps-next{
    display: flex;
    place-content: center;
}
.map-location{
    height: 500px;
    width: 100%;
    margin: 10px;
    color: #0c0c0c;
    background-color: #0c0c0c;
}
.back-color{
    background-color: #0c0c0c;
}
@media (max-width: 480px) {
    .map-location{
        width: 320px;
        height: 300px;
    }
    .maps-container{
        grid-template-columns: 1fr;
        padding-left: 10px;
        padding-right: 10px;
    }
    .maps-left{
        padding-left: 5%;
        padding-right: 5%;
        
    }
}