.myHome {
    display: grid;
    padding-top: 30px;
    padding-bottom: 50px;
    grid-template-columns: 1.3fr 1fr;
    background-color: #e3f2fa;
}

.myHomeleft {
    display: flex;
    flex-direction: column;
    padding: 100px;
    padding-left: 80px;
    transition: 1s ease;
    transition-delay: 0.5s;
    opacity: 0;
}

.myHomenext {
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    justify-content: center;
    opacity: 0;
    transition: 1s ease;
}

.imME {
    border: 1px solid rgb(167, 167, 167);
    margin-top: 100px;
    height:300px;
    width: auto;
    object-fit: contain;
    margin-right: 50px;
    border-radius: 10px;
}

.Nameis {
    font-size: 25px;
    padding-top: 10px;
}

.AbdullahText {
    padding-left: 10px;
    font-size: 35px;
    color: rgb(10, 40, 78);
    font-weight: 700;
}

.AboutHead{
    margin-top: -20px;
    font-size: 16px;
}

.NameHead {
    align-items: center;
    margin-top: -10px;
}

.ObjectiveText {
    opacity: 0.8;
    font-size: 15px;
    text-align: justify;
}
.ObjectiveText-1{
    margin-top: -20px;
    font-size: 15px;
    text-align: justify;
}

.Home2Buttons {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TouchSubHead {
    color: rgb(11, 49, 83);
    display: flex;
    gap: 4px;
    font-size: 18px;
}

.ButtonResume {
    height: 35px;
    width: 130px;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    border-width: 1px;
    border-color: rgb(11, 72, 165);
    background-color: transparent;
    font-weight: 600;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    color: rgb(11, 72, 165);
    margin-top: -10px;
}
.ButtonResume {
    height: 40px;
    width: 180px;
    font-size: 14px;
    border-width: 1px;
    color: rgb(16, 46, 80);
}

.ButtonResume:hover {
    background-color: #134874;
    border: 1px solid white;
    color: aliceblue;
}

.ButtonResume:hover .pdfIcon {
    color: aliceblue;
}

.TypeText {
    margin-top: 20px;
    font-size: 25px;
    margin-right: 30px;
    font-weight: 600;
    letter-spacing: 2px;
    color: rgb(15, 66, 133);
}

.Touchicon {
    transition: 0.1s;
    margin-top: 4px;
    animation: float 1.7s infinite;
    opacity: 0;
    padding-left: 1px;
}

.HelloAndLine {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Line {
    background-color: #000000;
    opacity: 0.5;
    width: 80px;
    margin-top: 18px;
}

.Hello {
    padding-left: 10px;
}

@keyframes float {
    0% {
        opacity: 1;
        transform: translateY(2px);
    }

    50% {
        opacity: 0;
        transform: translateY(-2px);
    }

    100% {
        opacity: 1;
        transform: translateY(2px);
    }
}

@media (max-width: 720px) {
    .myHome {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .myHome {
        grid-template-columns: 1fr;
        padding: 0;
        margin: 0;
        background-color: rgb(244, 249, 253);
    }

    .TypeText {
        padding-left: -10px;
    }

    .myHomeleft {
        padding: 0;
        margin: 0;
        margin-right: 20px;
        padding-left: 20px;
        padding-top: 80px;
    }
    .Home2Buttons{
        align-items: center;
    }
    .ButtonResume{
        background-color: #134874;
        color: white;
    }

    .imME {
        height: auto;
        margin-right: 0;
        margin-top: 40px;
        width: 320px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .myHomenext {
        padding-top: -50px;
    }
    .AboutHead{
        display: none;
    }
    .HelloLine{
        display: none;
    }
    .ObjectiveText-1{
        text-align: center;
    }
    .Nameis {
        font-size: 20px;
        margin-top: 10px;
    }

    .NameHead{
        margin-top: 10px;
        text-align: center;
    }

    .AbdullahText {
        margin-top: 7px;
        font-size: 35px;
    }

    .TypeText {
        font-family: Tahoma, Geneva, Verdana, sans-serif;
        font-size: 20px;
        letter-spacing: 2px;
        margin-top: 10px;
        color: rgb(15, 66, 133);
    }

    .ButtonResume {
        margin-top: 20px;
        height: 40px;
        width: 150px;
        padding-left: 12px;
    }

    .Touchicon {
        color: #0b3b77;
    }

    .TouchSubHead {
        color: #0b3b77;
        display: none;
    }

    .ObjectiveText {
        opacity: 0.7;
    }
}