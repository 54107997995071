.AboutContainer {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    margin-bottom: 50px;
    gap: 20px; /* Add spacing between columns */
}

.about-container-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.im-about {
    height: 650px;
    width: 650px;
    object-fit: contain;
    cursor: pointer;
    transition: transform 0.5s ease;
}

.im-about:hover {
    transform: scale(1.05);
}

.about-container-next {
    margin-right: 100px;
}

.text-line-1 {
    margin-bottom: 20px;
    font-size: 18px;
    color: rgb(13, 46, 65);
    font-weight: bold;
}

.text-line-1 hr {
    margin-top: 10px;
    border: 1px solid rgb(13, 46, 65);
}

.about-head {
    font-family: 'Playfair Display', serif;
    font-size: 50px;
    color: rgb(13, 46, 65);
    margin-bottom: 20px;
}

.about-para {
    font-size: 16px;
    line-height: 1.8;
    color: rgb(80, 80, 80);
}

@media (max-width: 780px) {
    .AboutContainer {
        grid-template-columns: 1fr; 
    }

    .about-container-left {
        justify-content: center;
    }

    .about-container-next {
        margin: 20px; 
        text-align: center; 
    }

    .im-about {
        height: 350px;
        width: 350px;
    }

    .about-head {
        font-size: 36px; 
    }

    .about-para {
        font-size: 15px; 
    }
}

@media (max-width: 480px) {
    .im-about {
        height: 600px;
        width: 100%;
    }

    .about-head {
        font-size: 28px; 
    }

    .about-para {
        font-size: 15px; 
    }
}
