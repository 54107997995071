.About {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: rgb(249, 252, 255);
    gap: 30px;
}

.about-left {
    display: flex;
    align-items: center;
    place-content: center;
}

.im-1 {
    height: 350px;
    object-fit: contain;
    border-radius: 5px;
}

.text-line-1 {
    display: flex;
}

.about-head {
    text-align: left;
    margin-top: -30px;
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    color: rgb(13, 46, 65);
}

.about-next{
    margin-top: 10px;
}

.about-para {
    text-align: justify;
    margin-top: 10px;
    font-weight: 500;
    color: black;
}
.about-read-link{
    margin-top: 20px;
}

@media (max-width: 480px) {
    .About {
        grid-template-columns: 1fr;
        padding-top: 40px;
        margin: 0px;
        margin-bottom: -100px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .about-left {
        order: 2;
    }

    .about-next {
        order: 1;
    }

    .about-left {
        display: flex;
        align-items: center;
        place-content: center;
    }

    .im-1 {
        height: 330px;
        width: 330px;
        object-fit: contain;
        border-radius: 5px;
        margin-top: -20px;
    }

    .about-head {
        text-align: left;
        margin-top: 0;
        font-family: 'Playfair Display', serif;
        font-size: 30px;
        color: rgb(13, 46, 65);
    }

    .about-para {
        text-align: justify;
        margin-top: -10px;
        font-size: 15px;
    }
}

