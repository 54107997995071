.updates {
    padding-top: 100px;
    height: auto;
    padding: 50px;
    background-color: white;
}

.updates-title {
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    color: rgb(13, 46, 65);
}

.update-posters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-left: 100px;
    margin-right: 100px;
}

.im-update {
    margin-top: 50px;
    height: 300px;
    width: 300px;
    object-fit: contain;
    border: 1px solid rgb(182, 182, 182);
    border-radius: 10px;
}

@media (max-width: 480px) {
    .update-posters {
        grid-template-columns: 1fr;
        place-items: center;
        gap: 0;
        margin-top: 0px;
        margin-left: 0;
        margin-right: 0;
    }
    .updates-title {
        text-align: center;
        font-family: 'Playfair Display', serif;
        font-size: 30px;
        color: rgb(13, 46, 65);
    }
    

    .updates {
        margin-top: 20px;
        height: auto;
    }

    .im-update {
        height: 250px;
        width: 250px;
        object-fit: contain;
    }
}