.header-bottom-1{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
}
.rect-2{
    background-color: #093141;
    width: 100%;
    height: 80px;
}
.scroll-icon{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #219b4e;
}
.scroll-text{
    color: rgb(75, 75, 75);
    font-size: 14px ;
}
.header-bottom-next{
    opacity: 0;
    display: flex;
    align-items: center;
    place-content: center;
    gap: 10px;
    transition: 0.5s ease;
}

@media (max-width: 780px) {
    .rect-2{
        height: 60px;
    }
}