.side-services {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.slide-icon-services{
    opacity: 1;
    color: rgb(20, 73, 134);
}
.side-services a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(20, 73, 134);
    font-size: 14px;
    font-weight: 500;
    padding: 10px 10px;
    border-radius: 8px;
    border: 1px solid rgba(20, 73, 134, 0.2);
    transition: all 0.3s ease;
}

.side-services a:hover {
    background-color: rgba(20, 73, 134, 0.1);
    color: rgb(20, 73, 134);
    transform: translateX(5px);
    box-shadow: 0 4px 6px rgba(20, 73, 134, 0.2);
}

.side-services a .dropdown-icon {
    margin-right: 10px;
    font-size: 20px;
    color: rgb(20, 73, 134);
    transition: color 0.3s ease;
}

.side-services a:hover .dropdown-icon {
    color: rgba(20, 73, 134, 0.7);
}
