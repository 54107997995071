.AboutContainer1 {
    display: grid;
    grid-template-columns: 0.85fr 1fr;
    margin-bottom: 50px;
    gap: 20px; 
}

.im-about-1 {
    margin-top: 0px;
    height: 650px;
    width: 650px;
    object-fit: contain;
    cursor: pointer;
    transition: transform 0.5s ease;
}

.im-about-1:hover {
    transform: scale(1.05);
}

.about-container1-left {
    margin-right: -20px;
}

.about-container-head-1 {
    margin-top: 50px;
    text-align: right;
    font-family: 'Playfair Display', serif;
    font-size: 50px;
    color: rgb(13, 46, 65);
}

.about-bottom-text {
    margin-left: 50px;
    text-align: justify;
    font-size: 16px;
    line-height: 1.8;
}

@media (max-width: 780px) {
    .AboutContainer1 {
        grid-template-columns: 1fr; 
    }

    .im-about-1 {
        margin-top: 0;
        height: 350px;
        width: 350px;
        object-fit: cover;
    }

    .about-container1-left {
        margin: 20px;
        text-align: center; 
    }

    .about-container-head-1 {
        font-size: 36px; 
        margin-top: 20px;
        text-align: left;
    }

    .about-bottom-text {
        margin-left: 0; 
        text-align: left;
        font-size: 15px; 
    }
}

@media (max-width: 480px) {
    .im-about-1 {
        height: 500px;
        width: 100%;
    }

    .about-container-head-1 {
        font-size: 28px;
    }

    .about-bottom-text {
        font-size: 15px;
    }
}
