.products {
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 100%;
    padding: 0 25px;
    margin: 0 auto;
    margin-top: 150px;
    gap: 35px;
    margin-bottom: 150px;
    box-sizing: border-box;
}

.product-link { 
    text-decoration: none; 
    width: 100%; 
}

  
.current-im {
    height: 200px;
    object-fit: contain;
    padding: 5px;
    cursor: pointer;
    transition: 0.5s ease;
    border: 1px solid rgba(68, 68, 68, 0.3);
}

.current-product {
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.current-product:hover .current-im {
    transform: scale(1.03);
}

.product-bar-2, .product-bar-4 {
    margin-top: 0px;
    height: 20px;
    background-color: #e6a122;
    transition: 1s ease;
    width: 0;
    opacity: 1;
}

.product-bar-1, .product-bar-3 {
    margin-top: 0px;
    height: 20px;
    background-color: #093141;
    transition: 1s ease;
    width: 0;
    opacity: 1;
}

.product-para {
    text-align: justify;
    color: #1b1b1b;
    line-height: 1.5;
}

.product-title {
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    color: #1b1b1b;
    font-size: 22px;
}

.product-icon {
    margin-top: 2px;
    color: rgb(31, 31, 31);
}

.product-button {
    display: flex;
    gap: 10px;
    text-align: left;
    padding: 10px;
    width: auto;
    height: 50px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    transition: 0.5s ease;
    font-weight: bold;
    margin-top: -10px;
}

.product-button:hover {
    background-color: black;
    color: white;
}

.product-button:hover .product-icon {
    color: white;
}

.product-line {
    color: black;
    background-color: black;
    width: 100%;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .products {
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
    }
}

@media (max-width: 900px) {
    .products {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 0 15px;
        margin-top: 100px;
        margin-bottom: 100px;
    }
}

@media (max-width: 600px) {
    .products {
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 0 10px;
        margin-top: 80px;
        margin-bottom: 80px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .product-button {
        font-size: 12px;
        padding: 8px;
        height: 45px;
    }

    .product-para {
        font-size: 14px;
    }

    .current-im {
        height: 300px;
    }
}
