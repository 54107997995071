.header {
    position: relative;
    height: 500px;
    width: 100%;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2) 60%, rgba(0, 0, 0, 0.8) 100%);
}

.header-grid {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    opacity: 0;
    transition: 1s ease;
}

.header-left {
    margin-top: 55px;
    margin-right: 100px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.header-next {
    margin-top: 30px;
    margin-left: 500px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    gap: 15px;
    justify-content: space-between;
}

.header-line {
    width: 100%;
    background-color: white;
    height: 1px;
}

.video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1; 
}

.header-bottom {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
}

.rect-1 {
    width: 0%;
    margin-top: -30px;
    background-color: #e6a122;
    height: 120px;
    transition: 1s ease;
    opacity: 0.9;
}

.head-white {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-align: left;
    letter-spacing: 2px;
}

.para-white {
    margin-top: -40px;
    text-align: left;
    font-size: 21px;
    line-height: 1.6;
    color: #ffffff;
    font-weight: 400;
}

.head-white-1 {
    margin-top: -30px;
    text-align: left;
    font-size: 60px;
    font-weight: 1000;
    color: #e6a122;
}
.contact-btn-2{
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 4px;
    margin-top: 0px;
    width: 35%;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s ease;
    text-decoration: none;
    border: none;
}
.link{
    text-decoration: none;
}
.contact-btn-2:hover{
    background-color: black;
    color: white;
    transform: translateY(-5px);
}

@media (max-width: 768px) {
    .header {
        height: 550px;
        padding-top: 20px;
        padding-bottom: -20px;
    }
    .header-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .header-left {
        margin: 10px;
        margin-top: 70px;
    }
    .header-next {
        display: none;
        margin: 0;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
    }
    .head-white {
        font-size: 24px;
        margin-top: 5px;
    }
    .head-white-1{
        font-size: 45px;
        margin-top: -5px;
    }
    .para-white {
        font-size: 16px;
        margin-top: -10px;
    }
    .rect-1 {
        margin-bottom: 0px;
        margin-top: 50px;

    }
}
