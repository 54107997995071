.AboutBanner-1{
    height: 350px;
    background-image: url('D:/ND Services/nd-services/public/about.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    color: white;
}