.Projects2 {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    padding-top: 100px;
    padding-bottom: 150px;
    background-color: white;
  }
  
  .Projects2Left {
    margin-left: 50px;
  }
  
  .Projects2Next {
    display: flex;
    align-items: center;
    place-content: center;
  }
  
  .MyProjects {
    display: flex;
    flex-direction: column;
  }
  
  .MyProjectsTop {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .MyProjectsBottom {
    margin-top: -20px;
    display: flex;
    flex-direction: row;
  }
  
  .imgContainer1,
  .imgContainer2,
  .imgContainer3 {
    text-align: center;
    margin: 10px;
    opacity: 0;
    transition: 1s;
  }
  
  .imgContainer1 {
    transition-delay: 0.5s;
  }
  
  .imgContainer2 {
    transition-delay: 1s;
  }
  
  .imgContainer3 {
    transition-delay: 1.5s;
  }
  
  .imgCaption,
  .imgCaption1 {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .imProject1,
  .imProject,
  .imProject2 {
    height: 200px;
    width: 220px;
    margin-bottom: -50px;
    border: #8d8d8d 1px solid;
    border-radius: 5px;
  }
  
  .imProject2 {
    margin-top: 30px;
  }
  
  .Bars {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: -15px;
    margin-bottom: 15px;
  }
  
  .MyBar {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
  
  .BarValue {
    opacity: 0.7;
    font-size: 14px;
  }
  
  .Bar {
    height: 10px;
    background-color: #cce2fc;
    width: 500px;
    border-radius: 4px;
    border: #868686 solid 1px;
  }
  
  .BarText {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }
  
  .Project2Head {
    font-family: 'Playfair Display', serif;
    font-size: 35px;
    color: rgb(23, 68, 90);
  }
  
  .Project2SubHead {
    margin-top: -20px;
    font-size: 20px;
    color: rgb(18, 64, 104);
    font-family: monospace;
  }
  
  .BarPercent1,
  .BarPercent2,
  .BarPercent3,
  .BarPercent4,
  .BarPercent5 {
    height: 10px;
    border-radius: 5px;
    transition: 1s;
    width: 0px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1024px) {
    .Projects2 {
      grid-template-columns: 1fr;
      padding-top: 30px;
    }
  
    .Projects2Left {
      margin: 50px;
    }
  
    .Projects2Next {
      margin-right: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .Projects2 {
      grid-template-columns: 1fr;
    }
  
    .Projects2Left {
      margin: 30px;
    }
  
    .Projects2Next {
      margin-right: 20px;
    }
  
    .imProject1,
    .imProject,
    .imProject2 {
      height: 200px;
      max-width: 200px;
    }
  
    .Project2Head {
      font-size: 30px;
    }
  
    .Project2SubHead {
      font-size: 20px;
      margin-top: 5px;
    }
  
    .Bar {
      height: 8px;
    }
  
    .BarPercent1,
    .BarPercent2,
    .BarPercent3,
    .BarPercent4,
    .BarPercent5 {
      height: 8px;
    }
  
    .BarValue {
      padding-top: 2px;
    }
  }
  
  @media (max-width: 480px) {
    .Projects2 {
      grid-template-columns: 1fr;
    }
  
    .Projects2Next {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  
    .Projects2Left {
      margin: 20px;
    }
  
    .Projects2Next {
      margin-right: 10px;
    }
  
    .imProject1,
    .imProject,
    .imProject2 {
      height: 150px;
      max-width: 150px;
    }
  
    .imgCaption,
    .imgCaption1 {
      margin-top: 20px;
      font-size: 10px;
    }
  
    .MyProjectsBottom {
      margin-top: 10px;
    }
  
    .Project2Head {
      font-size: 24px;
    }
  
    .Project2SubHead {
      font-size: 18px;
    }
  
    .Bar {
      width: 320px;
    }
  }