.reviews01 {
    text-align: center;
    padding: 20px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: white;
  }
  .review01-btn{
    margin-top: 50px;
  }
  .heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .reviews-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
  
  .review-img {
    margin-bottom: 0px;
    width: 700px;
    object-fit: contain;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  @media (max-width: 768px) {
    .reviews01 {
        padding: 50px 15px;
    }

    .review-img {
        margin-left: 10px;
        margin-right: 10px;
        width: 105%;
        max-width: 105%;
        margin-bottom: 0px;
    }
}